<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between" v-if="timesheetYearly.user">
      <h2>
        {{ users[timesheetYearly.user.id] | optional('firstname') }}
        {{ users[timesheetYearly.user.id] | optional('lastname') }}
      </h2>
      <h2>{{ year }}</h2>
    </div>
    <div v-for="month in months" v-bind:key="month.index"
         class="col-12 col-md-4">
      <card body-classes="standard-card-body" class="shadow">
        <div slot="header">
          <div class="d-flex align-items-center">
            <h4 class="card-title text-capitalize mb-0">{{ $t('common.' + month.label) }}</h4>
            <base-button
                         size="sm"
                         link
                         icon
                         @click="$router.push({
                         name: 'call-center.timesheet.operator.monthly',
                         params: {
                           id : operatorId,
                           year: year,
                           month: month.index.toString().padStart(2,'0')
                           }
                         })"
                         class="ml-auto">
              <octo-icon icon="right-arrow"/>
            </base-button>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <list-group-item-component
            :label="$t('callcenter.hours')"
            :value="getMonthlyHours(month.index)" />
          <list-group-item-component
            :label="$t('callcenter.type')"
            :value="getMonthlyType(month.index)" />
        </ul>
      </card>
    </div>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";

export default {
  name: "TimesheetOperatorYearlyPage",
  components: {ListGroupItemComponent, OctoIcon},
  data() {
    return {
      year: this.$route.params.year || this.$moment().year(),
      operatorId: this.$route.params.id,
      timesheetYearly: [],
      months: [
        {'index': 1, 'label': 'january'},
        {'index': 2, 'label': 'february'},
        {'index': 3, 'label': 'march'},
        {'index': 4, 'label': 'april'},
        {'index': 5, 'label': 'may'},
        {'index': 6, 'label': 'june'},
        {'index': 7, 'label': 'july'},
        {'index': 8, 'label': 'august'},
        {'index': 9, 'label': 'september'},
        {'index': 10, 'label': 'october'},
        {'index': 11, 'label': 'november'},
        {'index': 12, 'label': 'december'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    })
  },
  mounted() {
    this.$fullLoading.show();
    this.$api.get(endpoints.CALL_CENTER_TIMESHEET_OPERATOR_YEARLY
      .replace('{id}', this.operatorId)
      .replace('{year}', this.year)
    )
      .then((resp) => {
        this.timesheetYearly = resp.data;
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'call-center.timesheet'})
      })
    .finally(() => {
      this.$fullLoading.hide();
    })
  },
  methods: {
    getMonthlyType: function (month) {
      return this.timesheetYearly.hasOwnProperty(month)
        ? (this.timesheetYearly[month].type ? this.$t('callcenter.' + this.timesheetYearly[month].type) : 'N.D.')
        : '...';
    },
    getMonthlyHours: function (month) {
      return this.timesheetYearly.hasOwnProperty(month)
        ? this.timesheetYearly[month].hours
        : '...';
    }
  }
}
</script>

<style scoped>

</style>
